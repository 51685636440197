
import {computed, defineComponent, onMounted, ref} from "vue";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ReminderName from "@/views/reminder/ReminderName.vue";

export default defineComponent({
  name: "MyTasks",
  components: {ReminderName,  Avatar, DateTimeFormat, KtDatatable, Entities},
  setup() {
    const objectFilter = ref({my: true, complete: '', overdue: false})
    const page = computed(() => store.state.ReminderModule.allPage);
    const header = [
      {
        name: 'created',
        key: 'created',
        sortable: false
      },

      {
        name: 'Entity',
        key: 'related',
        sortable: false
      },
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'DueDate',
        key: 'dueDate',
        sortable: false
      },
    ]
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("My Reminders",
        [
          {link: false, router: '', text: 'Reminders'},
          {link: false, router: '', text: 'My Reminders'}
        ]
      )
    })
    return {
      objectFilter,
      header,
      ...LoadFilterObjects(Actions.LOAD_ALL_REMINDER, objectFilter.value, ['assignee', 'team', 'caze', 'claim']),
      page,
      ...LoadPanel()
    }
  },
  methods: {
    discardEvent() {
      this.objectFilter.complete = ''
      this.objectFilter.overdue = false
      this.updateFilterObject(this.objectFilter);
    },
    searchEvent() {
      this.updateFilterObject(this.objectFilter)
    },
  }
})
